import { useParams, Link } from "react-router-dom"
import { useQuery } from "@tanstack/react-query";

import { fetchConfirmEmail } from "../util/http";
import LoadingIndicator from "../components/LoadingIndicator";
import ErrorBlock from "../components/ErrorBlock";


export default function ConfirmEmail() {
    const { email_id } = useParams();
    let content = null;

    const { data, isPending, isError, error } = useQuery({
        queryKey: ['emails', { key: email_id }],
        queryFn: ({ signal, queryKey }) => fetchConfirmEmail({ signal, ...queryKey[1] }),
    });

    if (isPending) { content = <LoadingIndicator /> }

    if (isError) {
        content = (
            <ErrorBlock
                title="An error occurred"
                message={error.info?.message || 'Could not confirm email.\
                Either email is already confirmed or service might be temporarily down.\
                 Please try again later.'}
            />
        );
    }

    if (data) {
        content = (
            <div className="center">
                <h1 >Your email has been confirmed</h1>
                <h2 >You can now log in</h2>
                <Link className="btn btn-secondary" to="/login">Go to Login</Link>
            </div>
        );

    }

    return (
        <>
            {content}
        </>
    )
}