import { useQuery, useMutation } from "@tanstack/react-query";
import React, { useState, useRef, useEffect } from "react";
import { Button, Overlay, Alert } from "react-bootstrap";
import Table from 'react-bootstrap/Table';

import ExternalPasswordModal from "../components/ExternalPasswordModal";
import { scrollToTop } from "../util/utils";
import { fetchExternalEnrolledCompanies, queryClient, fetchDeleteEnrolledCompany } from "../util/http";

import './External.css'

export default function GetExternalPassword() {
    const [allertShow, setAllertShow] = useState(false)
    const [allertVariant, setAllertVariant] = useState("success")
    const [allertContent, setAllertContent] = useState("Oops")
    const [show, setShow] = useState(false);
    const target = useRef(null);

    const { data: enrolledCompanies, isError: enrolledCompaniesIsError, error: enrolledCompaniesError } = useQuery({
        queryKey: ['enrolledCompanies'],
        queryFn: ({ signal }) => fetchExternalEnrolledCompanies({ signal }),
    });

    useEffect(() => {
        if (allertShow) {
            scrollToTop();
            setTimeout(() => {
                setAllertShow(false);
            }, 2500);
        }
    }, [allertShow])

    function copyToClipboard(text) {
        setShow(!show)
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text)
            return //codes below wont be executed
        }
        const textArea = document.createElement("textarea")
        textArea.value = text

        document.body.appendChild(textArea)

        textArea.focus()
        textArea.select()

        // Depricated method used only by older browser versions 
        document.execCommand('copy')

        document.body.removeChild(textArea)
    }

    const { mutate: deleteCompanyId, isPending: updatePending } = useMutation({
        mutationFn: fetchDeleteEnrolledCompany,
        onSuccess: (data) => {
            setAllertVariant("success")
            // TODO
            setAllertContent("Kunde erfolgreich gelöscht!")
            setAllertShow(true)
            scrollToTop()
            queryClient.invalidateQueries({ queryKey: ['enrolledCompanies'] });
        },
        onError: () => {
            setAllertVariant("danger")
            // TODO
            setAllertContent("Das Löschen des Kuden ist fehlgeschlagen!")
            setAllertShow(true)

        }
    });

    function deleteCompany(id) {
        // TODO
        const answear = window.confirm("Seid ihr Sicher?")
        if (answear) {
            deleteCompanyId({ id })
        }
    }

    function listEnrolledCompanies() {
        if (!enrolledCompanies) return <><h1>No Entries Found</h1></>
        return (
            <>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Nummer</th>
                            <th>Kurzname/Sortiername</th>
                            <th>Name</th>
                            <th>Strasse</th>
                            <th>Land</th>
                            <th>PLZ</th>
                            <th>Ort</th>
                            <th>Bundesstaat</th>
                            <th>Passwort</th>
                        </tr>
                    </thead>
                    <tbody>
                        {enrolledCompanies.map((row) => (
                            <tr key={row.id}>
                                <td>{row.client_number}</td>
                                <td>{row.short_name}</td>
                                <td>{row.name}</td>
                                <td>{row.street}</td>
                                <td>{row.country}</td>
                                <td>{row.plz}</td>
                                <td>{row.ort}</td>
                                <td>{row.state}</td>
                                <td>{row.email_code}</td>
                                <td><Button variant="primary" ref={target} onClick={() => copyToClipboard(row.email_code)}>Kopieren</Button></td>
                                {/* TODO */}
                                <td><ExternalPasswordModal ButtonName={"Bearbeiten"} EditData={row} EditFlag={true} setAllertContent={setAllertContent} setAllertVariant={setAllertVariant} setAllertShow={setAllertShow} /></td>
                                {/* TODO */}
                                <td><Button variant="outline-danger" onClick={() => deleteCompany(row.id)}>Löschen</Button></td>

                                <Overlay target={target.current} show={show} placement="top">
                                    {({
                                        placement: _placement,
                                        arrowProps: _arrowProps,
                                        show: _show,
                                        popper: _popper,
                                        hasDoneInitialMeasure: _hasDoneInitialMeasure,
                                        ...props
                                    }) => (
                                        <div
                                            {...props}
                                            style={{
                                                position: 'absolute',
                                                backgroundColor: 'rgba(49, 119, 217, 0.8)',
                                                padding: '5px 15px',
                                                color: 'white',
                                                borderRadius: 5,
                                                ...props.style,
                                            }}
                                        >
                                            In die Zwischenablage kopiert
                                        </div>
                                    )}
                                </Overlay>

                            </tr>
                        ))}
                    </tbody>
                </Table>
            </>
        );
    }


    return (
        <>
            <Alert key={allertVariant} variant={allertVariant} show={allertShow}>
                {allertContent}
            </Alert>
            {listEnrolledCompanies()}
            <ExternalPasswordModal ButtonName={"Neuen Kunden erstellen"} setAllertContent={setAllertContent} setAllertVariant={setAllertVariant} setAllertShow={setAllertShow} />
        </>

    )
}

