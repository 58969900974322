import { Link, Form, useRouteLoaderData } from "react-router-dom";

// import pasLogo from '/public/logo.png'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { backendServerUrl } from "../util/http";


function ColorSchemesExample() {
    const access_token = useRouteLoaderData('root');


    function logedin() {
        return (
            <>
                <Link className="nav-link" to="/emails">Aufträge</Link>
                <Link className="nav-link" to="/history">Historie</Link>
                <Link className="nav-link" to='/contract_creation'>Kontrakt-Erstellen</Link>
                <Link className="nav-link" to='/external_passwort'>External-Passwort</Link>
                <Form action="/logout" method="post">
                    <button className="nav-link">Logout( {localStorage.getItem("first_name")} )</button>
                </Form>
            </>
        );
    }

    function unloged() {
        const externalFormLink = backendServerUrl + 'external-contract'

        return (
            <>
                <Link className="nav-link" to="/login">Login</Link>
                <Link className="nav-link" to="/register">Register</Link>
                {/* <Link className="nav-link" to='/external-contract'>External-Kontrakt</Link> */}
                <a className="nav-link" href={externalFormLink} target="_blank">
                    External-Kontrakt
                </a>
            </>
        );

    }

    return (
        <>

            <Navbar bg="dark" data-bs-theme="dark">
                <img src='/logoResized.png' alt="P-a-Systems logo"></img>
                <Container>
                    <Nav className="me-auto" >
                        {access_token ? logedin() : unloged()}
                    </Nav>
                </Container>
            </Navbar>

        </>
    );
}

export default ColorSchemesExample;