export default function LoadingIndicator() {
    return (
        <span style={{ display: 'block', textAlign: 'center' }}>
            <div className="lds-ring" >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </span>
    );
}