import { Form, useActionData, useNavigation, json, Link } from "react-router-dom";

import { resendConfirmationEmailUrl } from "../util/http";
import classes from './LoginForm.module.css';


export default function ResendConfirmationEmail() {
    const data = useActionData();
    const navigation = useNavigation();
    const isRegistering = navigation.state === 'submitting';

    return (
        <Form method='post' className={classes.form}>
            {data && (
                data.detail ? (
                    <div className="alert alert-success">
                        <strong>Email Sent!</strong> verification email has been sent.
                    </div>
                ) : (
                    <ul>
                        {Object.values(data).map((err) => (
                            <li key={err} className="alert alert-warning" >{err}</li>
                        ))}
                    </ul>
                ))}
            <p>
                <label htmlFor="email">Email address:</label>
                <input
                    id="email"
                    type="email"
                    name="email"
                    required
                    defaultValue={data ? data.email : ''}
                />
            </p>
            <div className={classes.actions}>
                <button disabled={isRegistering}>
                    {isRegistering ? 'Sending confirmation email...' : 'Resend Email'}
                </button>
            </div>
        </Form>
    )
}

export async function action({ request }) {
    const method = 'POST';
    const data = await request.formData();

    const registrationData = {
        email: data.get('email'),
    };

    let url = resendConfirmationEmailUrl;

    try {
        const response = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(registrationData),
        })

        if (response.status === 400) {
            return await response.json();
        }

        if (!response.ok) {
            throw json(
                { message: 'Could not comunicate with server while sending confirmation email' },
                {
                    status: response.status,
                }
            );
        }

        const resData = await response.json();
        return resData;
    }
    catch (error) {
        throw json(
            { message: 'Could not comunicate with server while sending confirmation email, please try again later...' },
            {
                status: 500,
            }
        );
    }
}