import Button from 'react-bootstrap/Button';

import LoadingIndicator from './LoadingIndicator';
import './CompletedEmailList.css'


export default function CompletedEmailList({ selectedCompletedEmail, children, handleSubmit, handleEdit, isSubmiting, handleDeleteForm, handleSubmitedEdit, handleSubmitedCancel }) {
    const wagonWeights = (selectedCompletedEmail.weight.replace(/\[|\]/g, '')).split(',');
    const wagonNr = (selectedCompletedEmail.transport_nr.replace(/\[|\]/g, '')).split(',');
    const wagonProduct = (selectedCompletedEmail.product.replace(/\[|\]/g, '')).split(',');
    const wagonMrn = (selectedCompletedEmail.mrn.replace(/\[|\]/g, '')).split(',');

    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false };

    let content = null;

    const renderWagons = () => {
        return wagonWeights.map((weight, index) => (
            <div key={index} className='bot-gap completed_wagon_form'>
                <div className='bold'>Wagon {index + 1}:</div>
                <div><span className='grey-text'><span className='title'>Wagonummer: </span> {wagonNr[index]}</span></div>
                <div><span className='grey-text'><span className='title'>Produkt: </span> {wagonProduct[index]}</span></div>
                <div><span className='grey-text'><span className='title'>Gewicht: </span> {weight}</span></div>
                {wagonMrn[index] !== '' && <div><span className='grey-text'><span className='title'>Mrn: </span> {wagonMrn[index]}</span></div>}
            </div>
        ));
    };


    if (selectedCompletedEmail) {
        content = (
            <div className='completed_email_form'>
                <div className='completed_email_card'>
                    <div className='bold'>{selectedCompletedEmail.counterpart}</div>
                    <div className='grey-text bot-gap'><span className='title'> Vermerke:</span> {selectedCompletedEmail.remarks}</div>
                    <div className='grey-text'><span className='title'>Lieferort: </span> {selectedCompletedEmail.deliver_place}</div>
                    <div className='grey-text'><span className='title'>Datum geladen: </span> {new Date(selectedCompletedEmail.date_load).toLocaleString('de-DE', options)}</div>
                    <div className='grey-text'><span className='title'>Auftrags Code: </span> {selectedCompletedEmail.auftrags_code}</div>
                    <div className='grey-text'><span className='title'>Verwiegung Bahnhof Code: </span> {selectedCompletedEmail.weighing_station}</div>
                    <div className='grey-text'><span className='title'>Zulaufnummer: </span> {selectedCompletedEmail.zulauf_nr}</div>
                    <div className='grey-text'><span className='title'>Lieferantnummer: </span> {selectedCompletedEmail.lieferant_nr}</div>
                    <div className='grey-text'><span className='title'>Vbf-Nr: </span> {selectedCompletedEmail.station_code}</div>
                    <div className='grey-text'><span className='title'>Erstellt am: </span> {selectedCompletedEmail.created_at}</div>
                    <div className='grey-text bot-gap'><span className='title'>ucn_cn: </span> {selectedCompletedEmail.ucn_cn}</div>
                    <div>{renderWagons()}</div>
                    {selectedCompletedEmail.status === 0 &&
                        <div className='button-container'>
                            <button type="button" className='btn btn-outline-primary' onClick={() => { handleEdit(selectedCompletedEmail, wagonWeights, wagonNr, wagonProduct, wagonMrn) }} >Formular bearbeiten</button>
                            {!isSubmiting ? <button type="button" className='btn btn-primary' onClick={() => { handleSubmit(selectedCompletedEmail.id) }}>Formular absenden</button> : <LoadingIndicator />}
                            <Button variant="danger" disabled={false} onClick={() => { handleDeleteForm(selectedCompletedEmail.id) }}>Formular abbrechen</Button>
                        </div>
                    }
                    {selectedCompletedEmail.status === 1 &&
                        <div className='button-container'>
                            <Button variant="warning" disabled={true}>Das Formular wird übermittelt.</Button>
                        </div>
                    }
                    {selectedCompletedEmail.status === 2 &&
                        <div className='button-container'>
                            <Button variant="success" disabled={true}>Formular übermittelt</Button>
                            <Button variant="btn btn-outline-primary" onClick={() => { handleSubmitedEdit(selectedCompletedEmail, wagonWeights, wagonNr, wagonProduct, wagonMrn) }}>Aktualisieren (TPOU)</Button>
                            <Button variant="btn btn-outline-danger" onClick={() => { handleSubmitedCancel(selectedCompletedEmail.id) }}>Stornieren (TPOC)</Button>
                        </div>
                    }
                    {selectedCompletedEmail.status === 3 &&
                        <div className='button-container'>
                            <Button variant="warning" disabled={true}>Auftrag aktualisieren...</Button>
                        </div>
                    }
                    {selectedCompletedEmail.status === 4 &&
                        <div className='button-container'>
                            <Button variant="success" disabled={true}>Erfolgreich aktualisiert</Button>
                            <Button variant="btn btn-outline-primary" onClick={() => { handleSubmitedEdit(selectedCompletedEmail, wagonWeights, wagonNr, wagonProduct, wagonMrn) }}>Auftrag Aktualisieren (TPOU)</Button>
                            <Button variant="btn btn-outline-danger" onClick={() => { handleSubmitedCancel(selectedCompletedEmail.id) }}>Auftrag Stornieren (TPOC)</Button>
                        </div>
                    }
                    {selectedCompletedEmail.status === 5 &&
                        <div className='button-container'>
                            <Button variant="btn btn-outline-danger" disabled={true}>Auftrag Stornieren...</Button>
                        </div>
                    }
                    {selectedCompletedEmail.status === 6 &&
                        <div className='button-container'>
                            <Button variant="danger" disabled={true}>Auftrag Storniert</Button>
                            {!isSubmiting ? <button type="button" className='btn btn-primary' onClick={() => { handleSubmit(selectedCompletedEmail.id) }}>Submit Order</button> : <LoadingIndicator />}
                        </div>
                    }
                </div>
            </div>
        );
    }

    return (
        <>
            {content}
            {children}
        </>
    );
}