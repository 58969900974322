import { Form, useActionData, useNavigation, json, useParams } from "react-router-dom";

import { resetPasswordUrl } from "../util/http";
import classes from './LoginForm.module.css';


export default function PasswordReset() {
    const { uid, token } = useParams();
    const data = useActionData();
    const navigation = useNavigation();
    const isRegistering = navigation.state === 'submitting';

    return (
        <Form method='post' className={classes.form}>
            <h2>Reset you Password</h2>
            {data && (
                data.detail ? (
                    <div className="alert alert-success">
                        <strong>Password has been reset!</strong> Password has been changed successfully.
                    </div>
                ) : (
                    <ul>
                        {Object.values(data).map((err) => (
                            <li key={err} className="alert alert-warning" >{err}</li>
                        ))}
                    </ul>
                ))}

            {/* uid and token are taken from the url and dont need to be shown */}
            <input type="hidden" name="uid" value={uid}></input>
            <input type="hidden" name="token" value={token}></input>

            <p>
                <label htmlFor="password1">New Password:</label>
                <input
                    id="password1"
                    type="password"
                    name="password1"
                    required
                    defaultValue={data ? data.password1 : ''}
                />
            </p>
            <p>
                <label htmlFor="password2">Repeat New Password:</label>
                <input
                    id="password2"
                    type="password"
                    name="password2"
                    required
                    defaultValue={data ? data.password2 : ''}
                />
            </p>
            <div className={classes.actions}>
                <button disabled={isRegistering}>
                    {isRegistering ? 'Comunicating with server...' : 'Reset Password'}
                </button>
            </div>
        </Form >);
}

export async function action({ request }) {
    const method = 'POST';
    const data = await request.formData();

    const registrationData = {
        new_password1: data.get('password1'),
        new_password2: data.get('password2'),
        uid: data.get('uid'),
        token: data.get('token'),
    };

    let url = resetPasswordUrl;

    try {
        const response = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(registrationData),
        })

        if (response.status === 400) {
            return await response.json();
        }

        if (!response.ok) {
            throw json(
                { message: 'Could not comunicate with server while sending password reset credentials' },
                {
                    status: response.status,
                }
            );
        }

        const resData = await response.json();
        return resData;
    }
    catch (error) {
        throw json(
            { message: 'Could not comunicate with server while sending password reset credentials, please try again later...' },
            {
                status: 500,
            }
        );
    }
}