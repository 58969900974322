import { Form, useNavigation, useActionData, json, redirect } from "react-router-dom";
import { Link } from "react-router-dom";

import classes from './LoginForm.module.css';
import { loginFormUrl } from "../util/http";


function LoginPage() {

    const data = useActionData();
    const navigation = useNavigation();
    const isLoggingIn = navigation.state === 'submitting';

    return (
        <Form method='post' className={classes.form}>
            {data && (
                <ul>
                    {Object.values(data).map((err) => (
                        <li key={err} style={{ color: 'red' }} >{err}</li>
                    ))}
                </ul>
            )
            }
            <p>
                <label htmlFor="email">E-Mail-Adresse:</label>
                <input
                    id="email"
                    type="email"
                    name="email"
                    required
                    defaultValue={data ? data.email : ''}
                />
            </p>
            <p>
                <label htmlFor="password">Passwort:</label>
                <input
                    id="password"
                    type="password"
                    name="password"
                    required
                    defaultValue={data ? data.password : ''}
                />
            </p>
            <div className={classes.actions}>
                <button disabled={isLoggingIn}>
                    {isLoggingIn ? 'Anmelden...' : 'Anmeldung'}
                </button>
                <Link className="btn btn-outline-secondary" to="/forgot_password">Passwort vergessen</Link>
            </div>
        </Form >
    );

}


export default LoginPage;


export async function action({ request }) {
    const method = 'POST';
    const data = await request.formData();

    const authData = {
        email: data.get('email'),
        password: data.get('password'),
    };

    let url = loginFormUrl;

    try {
        const response = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(authData),
        })

        if (response.status === 400) {
            return await response.json();
        }

        if (!response.ok) {
            console.log(response);
            throw json(
                { message: 'Could not comunicate with server while sending login credentials' },
                {
                    status: response.status,
                }
            );
        }

        const resData = await response.json();
        const access_token = resData.access_token;
        const first_name = resData.user.first_name;
        localStorage.setItem('access_token', access_token)
        localStorage.setItem('first_name', first_name)

        const expiration = new Date();
        expiration.setMinutes(expiration.getMinutes() + 120);
        localStorage.setItem("expiration", expiration.toISOString());

        return redirect('/emails');
    }
    catch (error) {
        throw json(
            { message: 'Could not comunicate with server while sending login credentials, please try again later...' },
            {
                status: 500,
            }
        );
    }
}