import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { Button, Form, Modal, Alert } from 'react-bootstrap';

import { fetchExternalListedCompanies, queryClient, fetchCreateEnrolledCompany, fetchUpdateEnrolledCompany, } from "../util/http";
import LoadingIndicator from './LoadingIndicator';


function ExternalPasswordModal({ ButtonName, EditData = null, EditFlag = false, setAllertVariant, setAllertContent, setAllertShow }) {
    const [show, setShow] = useState(false);

    const [companyDetails, setCompanyDetails] = useState()
    const [companyList, setCompanyList] = useState()

    const handleClose = () => {
        setShow(false)
        setFormData({
            id: '',
            client_number: '',
            short_name: '',
            name: '',
            street: '',
            country: '',
            plz: '',
            ort: '',
            state: '',
            email_code: '',
        })
    };
    const handleShow = () => setShow(true);

    const { data: listedCompanies, isFetched, isFetching, isError: listedCompaniesIsError, error: listedCompaniesError } = useQuery({
        queryKey: ['external-password'],
        queryFn: ({ signal }) => fetchExternalListedCompanies({ signal }),
    });

    const [formData, setFormData] = useState({
        id: '',
        client_number: '',
        short_name: '',
        name: '',
        street: '',
        country: '',
        plz: '',
        ort: '',
        state: '',
        email_code: '',
    });

    // create dict from string array
    useEffect(() => {
        if (isFetched) {
            let list = []
            listedCompanies.map((row) => {
                const dict = {
                    client_number: '',
                    short_name: '',
                    name: '',
                    street: '',
                    country: '',
                    plz: '',
                    ort: '',
                    state: '',
                }
                const keys = Object.keys(dict);
                const values = row.row.split(',')
                keys.forEach((key, index) => {
                    dict[key] = values[index];
                });
                list.push(dict)
            }
            )
            setCompanyList(list)
        }
        // Ensure this effect runs only when 'listedCompanies' changes
    }, [listedCompanies]);

    // used to populate during edit
    useEffect(() => {
        if (EditData !== null) {
            {
                setFormData({
                    id: EditData.id,
                    client_number: EditData.client_number,
                    short_name: EditData.short_name,
                    name: EditData.name,
                    street: EditData.street,
                    country: EditData.country,
                    plz: EditData.plz,
                    ort: EditData.ort,
                    state: EditData.state,
                    email_code: EditData.email_code,
                })
            }
        }
    }, [EditData, show]);

    // used when selected
    useEffect(() => {
        if (companyDetails) {
            setFormData({
                ...formData,
                client_number: companyDetails.client_number,
                short_name: companyDetails.short_name,
                name: companyDetails.name,
                street: companyDetails.street,
                country: companyDetails.country,
                plz: companyDetails.plz,
                ort: companyDetails.ort,
                state: companyDetails.state,
            })
        }
        // Ensure this effect runs only when 'auf_code' changes
    }, [companyDetails]);

    function findObject(itemKey) {
        const itemCode = itemKey.split(' | ')[0]
        // Find the index of the object with the specified item key
        const index = companyList.findIndex(obj => obj.client_number === itemCode);
        if (index !== -1) {
            setCompanyDetails(companyList[index]);
        }
    };

    const { mutate: createCompany, isPending } = useMutation({
        mutationFn: fetchCreateEnrolledCompany,
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['enrolledCompanies'] });
            setAllertVariant("success")
            // TODO
            setAllertContent("Kunde erfolgreich erstellt!")
            setAllertShow(true)
        },
        onError: () => {
            setAllertContent("Die Kundeerstellung ist fehlgeschlagen!")
            setAllertVariant("danger")
            setAllertShow(true)
        }
    });

    const { mutate: UpdateCompany, isPending: updatePending } = useMutation({
        mutationFn: fetchUpdateEnrolledCompany,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['enrolledCompanies'] });
            // TODO
            setAllertContent("Kunde erfolgreich aktualisiert")
            setAllertVariant("success")
            setAllertShow(true)
        },
        onError: () => {
            // TODO
            setAllertContent("Der Kunde konnte nicht aktualisiert werden")
            setAllertVariant("danger")
            setAllertShow(true)
        }
    });

    const handleSave = () => {
        if (EditFlag) {
            UpdateCompany({ formData })
        }
        else {
            createCompany({ formData })
        }
        handleClose();
    };

    return (
        <>
            {!companyList &&
                <LoadingIndicator />
            }
            {companyList &&
                <>
                    <Button variant="primary" onClick={handleShow}>
                        {ButtonName}
                    </Button>

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Firmendetails</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Filter </Form.Label>
                                    <input list="data" onChange={(e) => findObject(e.target.value)} placeholder="Type to filter" />
                                    <datalist id="data">
                                        {companyList.map((op) => <option key={op.client_number}>{op.client_number} | {op.name} | {op.country}</option>)}
                                    </datalist>
                                    <Form.Label>Kurzname/Sortiername</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        value={formData.short_name}
                                        onChange={(event) => setFormData({ ...formData, short_name: event.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group
                                    className="mb-3">
                                    <Form.Label>Nummer</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        value={formData.client_number}
                                        onChange={(event) => setFormData({ ...formData, client_number: event.target.value })}
                                    />
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        value={formData.name}
                                        onChange={(event) => setFormData({ ...formData, name: event.target.value })}
                                    />
                                    <Form.Label>Strasse</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        value={formData.street}
                                        onChange={(event) => setFormData({ ...formData, street: event.target.value })}
                                    />
                                    <Form.Label>Land</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        value={formData.country}
                                        onChange={(event) => setFormData({ ...formData, country: event.target.value })}
                                    />
                                    <Form.Label>PLZ</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        value={formData.plz}
                                        onChange={(event) => setFormData({ ...formData, plz: event.target.value })}
                                    />
                                    <Form.Label>Ort</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        value={formData.ort}
                                        onChange={(event) => setFormData({ ...formData, ort: event.target.value })}
                                    />
                                    <Form.Label>Bundesstaat</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        value={formData.state}
                                        onChange={(event) => setFormData({ ...formData, state: event.target.value })}
                                    />
                                    <Form.Label>Ihr Passwort lautet</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Leave empty for random"
                                        value={formData.email_code}
                                        onChange={(event) => setFormData({ ...formData, email_code: event.target.value })}
                                    />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                {/* TODO */}
                                Schließen
                            </Button>
                            <Button variant="primary" onClick={handleSave} disabled={updatePending}>
                                {/* TODO */}
                                Änderungen speichern
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            }
        </>
    );
}


export default ExternalPasswordModal;

