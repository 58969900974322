function HomePage() {

    return <>
        <h1 style={{ textAlign: 'center' }}>Willkommen auf der P-A-Systems OSR-Webseite, bitte wählen Sie aus.
            <span style={{ fontWeight: 'bold', color: ' #5684f6 ' }}> Login </span>or
            <span style={{ fontWeight: 'bold', color: ' #5684f6 ' }}> Register</span></h1>

    </>
}

export default HomePage;
