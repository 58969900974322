import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import CloseButton from 'react-bootstrap/CloseButton';
import Button from 'react-bootstrap/Button';

import CompletedEmailList from './CompletedEmailList'
import './CompletedEmail.css'
import { fetchCorrectedEmailsOfEmail, fetchGenerateXml, fetchGenerateXmlUpdate, fetchGenerateXmlCancel, queryClient, fetchDeleteForm, fetchUpdateForm } from '../util/http'
import { scrollToTop } from "../util/utils";
import LoadingIndicator from './LoadingIndicator';
import ErrorBlock from './ErrorBlock';
import Modal from './UI/Modal';
import AlertDismissible from './AlertDismissible';



export default function CompletedEmail({ selectedEmail }) {
    let content = null;
    const [submitedFormEditFlag, setSubmitFormEditFlag] = useState(false)
    const [pageContext, setPageContext] = useState('CompletedEmail');
    const [formData, setFormData] = useState(null)
    const [editModal, setEditModal] = useState(null);
    const [selectedForm, setSelectedForm] = useState();
    const [wagonWeights, setWagonWeights] = useState();
    const [wagonMrn, setWagonMrn] = useState();
    const [wagonNr, setWagonNr] = useState();
    const [wagonProduct, setWagonProduct] = useState();
    const [show, setShow] = useState(false);
    const [alertVariant, setAlertVariant] = useState('success');


    useEffect(() => {

        if (selectedForm) {
            const wagonsWeightsIsArray = Array.isArray(wagonWeights);
            let wagonsLoader = { wagons: [] };
            if (wagonsWeightsIsArray) {

                for (let index = 0; index < wagonWeights.length; index++) {
                    const buffer = { wagon_nr: wagonNr[index], gewicht: wagonWeights[index], produkt: wagonProduct[index], mrn: wagonMrn[index] }
                    wagonsLoader = { wagons: [...wagonsLoader.wagons, buffer] }
                }
            }


            setFormData({
                counterpart: selectedForm?.counterpart,
                remarks: selectedForm?.remarks,
                deliver_place: selectedForm?.deliver_place,
                date_load: selectedForm?.date_load,
                auftrags_code: selectedForm?.auftrags_code,
                zulauf_nr: selectedForm?.zulauf_nr,
                lieferant_nr: selectedForm?.lieferant_nr,
                station_code: selectedForm?.station_code,
                wagons: wagonsLoader.wagons,
                weighing_station: selectedForm?.weighing_station,
            });
        }
    }, [selectedForm, wagonWeights, wagonNr, wagonProduct, wagonMrn]);


    const { mutate: deleteMutate } = useMutation({
        mutationFn: fetchDeleteForm,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['emails'] });
            queryClient.invalidateQueries({ queryKey: ['emails-history'] });
            scrollToTop();
        },
        onError: () => {
            setPageContext('DeleteForm');
            setShow(true);
            setAlertVariant('danger');
            scrollToTop();
        }
    });

    function EditCompletedForm(selectedForm, wagonWeights, wagonNr, wagonProduct, wagonMrn) {
        setSelectedForm(selectedForm);
        setWagonWeights(wagonWeights);
        setWagonNr(wagonNr);
        setWagonProduct(wagonProduct);
        setWagonMrn(wagonMrn);
    }

    function handleSubmitedEdit(selectedForm, wagonWeights, wagonNr, wagonProduct, wagonMrn) {
        setSubmitFormEditFlag(true);
        EditCompletedForm(selectedForm, wagonWeights, wagonNr, wagonProduct, wagonMrn);
    }

    function handleDeleteForm(id) {
        const answear = window.confirm("Sind Sie sicher, dass Sie dieses Formular löschen möchten?")
        if (answear) {
            deleteMutate({ id });
        }
    }

    const { mutate: updateMutate } = useMutation({
        mutationFn: fetchUpdateForm,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['emails'] });
            queryClient.invalidateQueries({ queryKey: ['emails-history'] });
            setPageContext('UpdateForm');
            setShow(true);
            setAlertVariant('success');
            scrollToTop();
            setEditModal(null)
            if (submitedFormEditFlag) {
                const id = selectedForm.id
                xmlUpdate({ id })
                setSubmitFormEditFlag(false)
            }
        },
        onError: () => {
            setPageContext('UpdateForm');
            setShow(true);
            setAlertVariant('danger');
            scrollToTop();
            setEditModal(null)
        }
    });

    useEffect(() => {
        editPageForm(setEditModal, formData, setFormData, selectedForm, updateMutate);
    }, [formData, selectedForm]);


    const { data, isPending, isError, error } = useQuery({
        queryKey: ['emails', { email_uuid: selectedEmail.id }],
        queryFn: ({ signal, queryKey }) => fetchCorrectedEmailsOfEmail({ signal, ...queryKey[1] }),
        staleTime: 30000, // half minute
        gcTime: 1000
    });

    if (isPending) {
        content = <LoadingIndicator />;
    }

    if (isError) {
        content = (
            <ErrorBlock
                title="An error occurred"
                message={error.info?.prediction.message || 'Failed to fetch completed emails.'}
            />
        );
    }

    const { mutate, isPending: MutationPending } = useMutation({
        mutationFn: fetchGenerateXml,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['emails'] });
            queryClient.invalidateQueries({ queryKey: ['emails-history'] });
            setPageContext('CompletedEmail');
            setShow(true);
            setAlertVariant('success');
            scrollToTop();
        },
        onError: () => {
            setPageContext('CompletedEmail');
            setShow(true);
            setAlertVariant('danger');
            scrollToTop();
        }
    });

    const { mutate: xmlUpdate } = useMutation({
        mutationFn: fetchGenerateXmlUpdate,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['emails'] });
            queryClient.invalidateQueries({ queryKey: ['emails-history'] });
            setPageContext('UpdateOrder');
            setShow(true);
            setAlertVariant('success');
            scrollToTop();
            setSubmitFormEditFlag(false)
        },
        onError: () => {
            setPageContext('UpdateOrder');
            setShow(true);
            setAlertVariant('danger');
            scrollToTop();
        }
    });

    const { mutate: xmlCancel } = useMutation({
        mutationFn: fetchGenerateXmlCancel,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['emails'] });
            queryClient.invalidateQueries({ queryKey: ['emails-history'] });
            setPageContext('CancelOrder');
            setShow(true);
            setAlertVariant('success');
            scrollToTop();
        },
        onError: () => {
            setPageContext('CancelOrder');
            setShow(true);
            setAlertVariant('danger');
            scrollToTop();
        }
    });

    function handleFormSubmit(id) {
        const answear = window.confirm("Submit Form?")
        if (answear) {
            mutate({ id })
        }
    }

    function handleSubmitedCancel(id) {
        const answear = window.confirm("Cancel Submited Form?")
        if (answear) {
            xmlCancel({ id })
        }
    }


    if (data) {
        content = (
            <div className="completed_scrollable_div">
                {data.corrected_emails.length > 0 ? data.corrected_emails.map((corrected_email, index) => (
                    <CompletedEmailList key={index} selectedCompletedEmail={corrected_email} handleSubmit={handleFormSubmit}
                        handleEdit={EditCompletedForm} isSubmiting={MutationPending} handleDeleteForm={handleDeleteForm} handleSubmitedEdit={handleSubmitedEdit} handleSubmitedCancel={handleSubmitedCancel} />
                )) :
                    <h2>Es wurden keine Formulare gefunden.</h2>}
            </div>
        )
    }

    return (
        <>
            <AlertDismissible show={show} setShow={setShow} variant={alertVariant} pageContext={pageContext} />
            {editModal}
            {content}
        </>
    );
}






function editPageForm(setEditModal, formData, setFormData, selectedForm, updateMutate) {
    function handleChange(index, event) {
        const { name, value } = event.target;
        const wagons = [...formData.wagons];
        wagons[index][name] = value;
        setFormData({ ...formData, wagons: wagons });
    };

    function handleUpdate() {
        setEditModal(
            <Modal>
                <LoadingIndicator />
            </Modal>
        );
        const jsonWaognsWeights = formData.wagons.map((wagon) => { return wagon.gewicht })
        const jsonWagonsProducts = formData.wagons.map((wagon) => { return wagon.produkt })
        const jsonWagonsWagonNr = formData.wagons.map((wagon) => { return wagon.wagon_nr })
        const jsonWagonsMrn = formData.wagons.map((wagon) => { return wagon.mrn })
        const id = selectedForm.id

        const updateForm = {
            weight: '[' + jsonWaognsWeights + ']',
            product: '[' + jsonWagonsProducts + ']',
            transport_nr: '[' + jsonWagonsWagonNr + ']',
            mrn: '[' + jsonWagonsMrn + ']',
            counterpart: formData?.counterpart,
            remarks: formData?.remarks,
            deliver_place: formData?.deliver_place,
            date_load: formData?.date_load,
            auftrags_code: formData?.auftrags_code,
            zulauf_nr: formData?.zulauf_nr,
            lieferant_nr: formData?.lieferant_nr,
            station_code: formData?.station_code,
            weighing_station: formData?.weighing_station,
        }

        updateMutate({ updateForm, id });
        console.log('updated form')
    }

    if (formData !== null) {
        setEditModal(
            <Modal>
                <CloseButton className='rightmost-corner-element' onClick={() => setEditModal(null)} />

                <div className='completed_email_form'>
                    <div className='completed_email_card'>
                        <div className='grey-text bot-gap'><span className='title'> Counterpart:</span> {selectedForm?.counterpart}
                            <input
                                type="text"
                                name="counterpart"
                                value={formData.counterpart}
                                required={true}
                                onChange={(event) => setFormData({ ...formData, counterpart: event.target.value })}
                            />
                        </div>
                        <div className='grey-text bot-gap'><span className='title'> Vermerke:</span>
                            <textarea
                                name="remarks"
                                className='remarks_textarea'
                                value={formData.remarks}
                                required={false}
                                onChange={(event) => setFormData({ ...formData, remarks: event.target.value })}
                            />
                        </div>
                        <div className='grey-text'><span className='title'>Lieferort: </span> {selectedForm?.deliver_place}
                            <input
                                type="text"
                                name="deliver_place"
                                value={formData.deliver_place}
                                required={true}
                                onChange={(event) => setFormData({ ...formData, deliver_place: event.target.value })}
                            />
                        </div>
                        <div className='grey-text'><span className='title'>Datum geladen: </span>
                            <input
                                type="datetime-local"
                                name="date_load"
                                value={formData.date_load}
                                required={true}
                                onChange={(event) => setFormData({ ...formData, date_load: event.target.value })}
                            />
                        </div>
                        <div className='grey-text'><span className='title'>Auftrags Code: </span> {selectedForm?.auftrags_code}
                            <input
                                type="text"
                                name="auftrags_code"
                                value={formData.auftrags_code}
                                required={true}
                                onChange={(event) => setFormData({ ...formData, auftrags_code: event.target.value })}
                            />
                        </div>
                        <div className='grey-text'><span className='title'>Verwiegung Bahnhof Code: </span> {selectedForm?.zulauf_nr}
                            <input
                                type="text"
                                name="weighing_station"
                                value={formData.weighing_station}
                                required={false}
                                onChange={(event) => setFormData({ ...formData, weighing_station: event.target.value })}
                            />
                        </div>
                        <div className='grey-text'><span className='title'>Zulaufnummer: </span> {selectedForm?.zulauf_nr}
                            <input
                                type="text"
                                name="zulauf_nr"
                                value={formData.zulauf_nr}
                                required={true}
                                onChange={(event) => setFormData({ ...formData, zulauf_nr: event.target.value })}
                            />
                        </div>
                        <div className='grey-text'><span className='title'>Lieferantnummer: </span> {selectedForm?.lieferant_nr}
                            <input
                                type="text"
                                name="lieferant_nr"
                                value={formData.lieferant_nr}
                                required={true}
                                onChange={(event) => setFormData({ ...formData, lieferant_nr: event.target.value })}
                            />
                        </div>
                        <div className='grey-text'><span className='title'>Vbf-Nr.: </span> {selectedForm?.station_code}
                            <input
                                type="text"
                                name="station_code"
                                value={formData.station_code}
                                required={true}
                                onChange={(event) => setFormData({ ...formData, station_code: event.target.value })}
                            />
                        </div>

                        <div className='grey-text'><span className='title'>Erstellt am: </span> {selectedForm?.created_at}</div>

                        {formData.wagons.map((wagon, index) => (

                            <div key={index} className='bot-gap completed_wagon_form'>
                                <div className='bold'>Wagon {index + 1}:</div>
                                <div><span className='grey-text'><span className='title'>Wagonummer: </span>
                                    <input
                                        type="text"
                                        name="wagon_nr"
                                        value={wagon.wagon_nr}
                                        required={true}
                                        onChange={(event) => handleChange(index, event)}
                                    />
                                </span></div>
                                <div><span className='grey-text'><span className='title'>Produkt: </span>
                                    <input
                                        type="text"
                                        name="produkt"
                                        value={wagon.produkt}
                                        required={true}
                                        onChange={(event) => handleChange(index, event)}
                                    />
                                </span></div>
                                <div><span className='grey-text'><span className='title'>Gewicht: </span>
                                    <input
                                        type="text"
                                        name="gewicht"
                                        value={wagon.gewicht}
                                        required={true}
                                        onChange={(event) => handleChange(index, event)}
                                    />
                                </span></div>
                                <div><span className='grey-text'><span className='title'>Mrn: </span>
                                    <input
                                        type="text"
                                        name="mrn"
                                        value={wagon.mrn}
                                        required={false}
                                        onChange={(event) => handleChange(index, event)}
                                    />
                                </span></div>

                            </div>
                        ))}
                    </div>
                </div>
                <Button variant="primary" onClick={handleUpdate}>Aktualisieren</Button>
                <div className='bot-gap' />
                <Button variant="outline-danger" onClick={() => setEditModal(null)}>Abbrechen</Button>
            </Modal>
        );
    }
}