// import classes from './currentlySelectedEmail.module.css'


export default function CurrentlySelectedEmail({ selectedEmail, children }) {
    let content = null;

    if (selectedEmail) {
        content = (
            <>
                <p><b>Email Sender: </b>{selectedEmail.sender}</p>
                <p><b>Subject: </b>{selectedEmail.subject}</p>
                <p><b>Time Created: </b> {selectedEmail.created_at}</p>
                <b><br></br>Email Body: <br></br></b><pre style={{ whiteSpace: 'pre-wrap', fontFamily: 'Gupter', fontSize: '16px' }}>{selectedEmail.body}</pre>
                <p><br />{children}</p>
                {/* Add more details here */}
            </>
        );
    }

    return (
        <>
            {content}
        </>
    );
}