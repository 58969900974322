import { useRouteError } from 'react-router-dom';

import ColoredNavBar from "../components/Navbar"
import PageContent from '../components/PageContent';

function ErrorPage() {
    const error = useRouteError();

    let title = 'An error occurred!';
    let message = 'Something went wrong!';

    if (error.status === 500) {
        message = error.data.message;
    }

    if (error.status === 404) {
        title = 'Not found!';
        message = 'Could not find resource or page.';
    }


    return (
        <>
            <ColoredNavBar />
            <PageContent title={title}>
                <h4>{message}</h4>
            </PageContent>
            <footer className='text-center text-lg-start bg-light text-muted mt-4'>
                <div className='text-center p-4'>

                    <a target='_blank' rel='noreferrer' className='text-reset fw-bold text-decoration-none' href='https://p-a-systems.com/'>
                        © Copyright <b>P-A-Systems</b>. All Rights Reserved
                    </a>
                </div>
            </footer>
        </>
    );
}

export default ErrorPage;