import { queryClient } from "./http";

export function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}

export function handleRefreshButton() {
    queryClient.invalidateQueries();
    console.log("feeling refreshed")
}