import { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import CurrentlySelectedEmail from '../components/CurrentlySelectedEmail';
import classes from './Emails.module.css'
import EmailList from "../components/EmailsList";
import { Pagination } from 'react-bootstrap';


export default function ConfirmEmail() {
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);

    let content = null;

    //TODO dev data
    const data = {
        results: [
            {
                id: 1,
                sender: 'Levi.Cord@company.com',
                created_at: '1/1/2024',
                subject: 'Lorem ipsum',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            },
            {
                id: 2,
                sender: 'Torsten.Dietfried@company.com',
                created_at: '1/1/2024',
                subject: 'Lorem ipsum',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            },
            {
                id: 3,
                sender: 'Volker.Vinzenz@company.com',
                created_at: '1/1/2024',
                subject: 'Lorem ipsum',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            },
        ],
    }

    if (data) {
        content = (
            <div className={classes.app_container}>
                <div className={classes.column}>
                    <h2>Kontrakt-Erstellen</h2>
                    <EmailList emails={data} selectedEmail={selectedEmail} setSelectedEmail={setSelectedEmail} />
                    <Pagination>
                        <Pagination.First onClick={() => setCurrentPage(1)} />
                        <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} />
                        <Pagination.Item active>{currentPage}</Pagination.Item>
                        <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} disabled={data.next === null} />
                    </Pagination>
                </div>
                {selectedEmail && <>
                    <div className={classes.column}>
                        <h2>Email Preview</h2>
                        <CurrentlySelectedEmail selectedEmail={selectedEmail}></CurrentlySelectedEmail>
                    </div>
                    <div className={classes.column}>
                        <DropdownButton
                            key={'Primary'}
                            id={`dropdownList`}
                            variant={'primary'}
                            title={'select Form'}
                        >
                            <Dropdown.Item eventKey="1" active={selectedOption === 'EK'} onClick={() => { setSelectedOption('EK') }}>EK</Dropdown.Item>
                            <Dropdown.Item eventKey="2" active={selectedOption === 'VK'} onClick={() => { setSelectedOption('VK') }}>VK</Dropdown.Item>
                            <Dropdown.Item eventKey="3" active={selectedOption === 'Strecke'} onClick={() => { setSelectedOption('Strecke') }}>Strecke</Dropdown.Item>
                        </DropdownButton>
                        {selectedOption &&
                            <>
                                <div><br /><h3>Selected Form: {selectedOption}</h3> </div>
                                <div >
                                    <label >Field 1:
                                        <input
                                            type='text'
                                            name="deliver_place"
                                            required={true}
                                        />
                                    </label>
                                </div>

                                <div >
                                    <label >Field 2:
                                        <input
                                            type='text'
                                            name="deliver_place"
                                            required={true}
                                        />
                                    </label>
                                </div>

                                <div >
                                    <label >Field 3:
                                        <input
                                            type='text'
                                            name="deliver_place"
                                            required={true}
                                        />
                                    </label>
                                </div>
                                <div>...</div>
                                <div >
                                    <label >Field n:
                                        <input
                                            type='text'
                                            name="deliver_place"
                                            required={true}
                                        />
                                    </label>
                                </div>

                                <div >
                                    <label >Date:
                                        <input
                                            type="datetime-local"
                                            name="date_load"
                                            required={true}
                                        />
                                    </label>
                                </div>
                            </>
                        }
                    </div>
                </>}
            </div>
        );
    }

    return (
        <>
            {content}
        </>
    );
}