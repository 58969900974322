import { Form, useActionData, useNavigation, json, Link } from "react-router-dom";

import { registerFormUrl } from "../util/http";
import classes from './LoginForm.module.css';


export default function Register() {
    const data = useActionData();
    const navigation = useNavigation();
    const isRegistering = navigation.state === 'submitting';

    return (
        <Form method='post' className={classes.form}>
            {data && (
                data.detail ? (
                    <div className="alert alert-success">
                        <strong>Registrierung erfolgreich!</strong> Bestätigungs-E-Mail wurde gesendet.
                    </div>
                ) : (
                    <ul>
                        {Object.values(data).map((err) => (
                            <li key={err} className="alert alert-warning" >{err}</li>
                        ))}
                    </ul>
                ))}
            <p>
                <label htmlFor="email">E-Mail-Adresse:</label>
                <input
                    id="email"
                    type="email"
                    name="email"
                    required
                    defaultValue={data ? data.email : ''}
                />
            </p>
            <p>
                <label htmlFor="first_name">Vorname:</label>
                <input
                    id="first_name"
                    type="first_name"
                    name="first_name"
                    required
                    defaultValue={data ? data.first_name : ''}
                />
            </p>
            <p>
                <label htmlFor="last_name">Nachname:</label>
                <input
                    id="last_name"
                    type="last_name"
                    name="last_name"
                    required
                    defaultValue={data ? data.last_name : ''}
                />
            </p>
            <p>
                <label htmlFor="password1">Passwort:</label>
                <input
                    id="password1"
                    type="password"
                    name="password1"
                    required
                    defaultValue={data ? data.password1 : ''}
                />
            </p>
            <p>
                <label htmlFor="password2">Passwort wiederholen:</label>
                <input
                    id="password2"
                    type="password"
                    name="password2"
                    required
                    defaultValue={data ? data.password2 : ''}
                />
            </p>
            <div className={classes.actions}>
                <button disabled={isRegistering}>
                    {isRegistering ? 'Registrierung...' : 'Registrierung'}
                </button>
                <Link className="btn btn-outline-secondary" to="/resend_confirmation_email">Bestätigungslink erneut senden</Link>
            </div>
        </Form >);
}

export async function action({ request }) {
    const method = 'POST';
    const data = await request.formData();

    const registrationData = {
        email: data.get('email'),
        first_name: data.get('first_name'),
        last_name: data.get('last_name'),
        password1: data.get('password1'),
        password2: data.get('password2'),
    };

    let url = registerFormUrl;

    try {
        const response = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(registrationData),
        })

        if (response.status === 400) {
            return await response.json();
        }

        if (!response.ok) {
            throw json(
                { message: 'Could not comunicate with server while sending registration credentials' },
                {
                    status: response.status,
                }
            );
        }

        const resData = await response.json();
        return resData;
    }
    catch (error) {
        throw json(
            { message: 'Could not comunicate with server while sending registration credentials, please try again later...' },
            {
                status: 500,
            }
        );
    }
}