import clases from './emailList.module.css'

function EmailList({ emails, selectedEmail, setSelectedEmail, pageContext }) {

    function statusButton(currentEmail) {
        return (
            pageContext ? (<div> <button type="button" className={currentEmail ? 'btn btn-primary' : 'btn btn-outline-primary'}
                disabled={true}  >{currentEmail ? 'Completed' : 'Open'}</button></div>) : ''
        );
    }

    return <>
        {emails.results?.map((email) => (
            <div onClick={() => { setSelectedEmail(email) }} key={email.id} className={clases.email_card} style={{ background: selectedEmail && selectedEmail.id === email.id ? ' #a5a8bd ' : '' }}>
                <div className={clases.sender}> {email.sender}</div>
                <div className={clases.created_time}>{email.created_at}</div>
                <div className={clases.subject}>{email.subject}</div>
                {statusButton(email.completed)}
            </div >))
        }

    </>
}

export default EmailList;