import { useState, useEffect } from 'react';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';


export default function AlertDismissible({ show, setShow, variant, pageContext }) {
    const [alertHeading, setAlertHeading] = useState();
    const [alertBody, setAlertBody] = useState();
    let buttonVariant = 'outline-' + variant;

    useEffect(() => {
        if (variant === 'success') {
            switch (pageContext) {
                case 'CompleteEmails':
                    setAlertHeading('Form submitted!');
                    setAlertBody('Form has been submitted successfully.');
                    break;
                case 'Emails':
                    setAlertHeading('Email completed');
                    setAlertBody('Email has been successfully completed')
                    break;
                case 'CompletedEmail':
                    setAlertHeading('Form submited');
                    setAlertBody('Form has been successfully submited')
                    break;
                case 'UpdateForm':
                    setAlertHeading('Form updated');
                    setAlertBody('Form has been successfully updated')
                    break;
                case 'UpdateOrder':
                    setAlertHeading('TPOU sent');
                    setAlertBody('Order update has been sent successfully')
                    break;
                case 'CancelOrder':
                    setAlertHeading('TPOC sent');
                    setAlertBody('Order cancelation has been sent successfully')
                    break;
                default:
                    setAlertHeading('Success!');
                    setAlertBody('Event has performed successfully.');
                    break;
            }
        }
        else {
            switch (pageContext) {
                case 'CompleteEmails':
                    setAlertHeading('Form submittion failed!');
                    setAlertBody('The form submittion has failed to communicate to the backend server.');
                    break;
                case 'Emails':
                    setAlertHeading('Failure!');
                    setAlertBody('Email failed to complete')
                    break;
                case 'CompletedEmail':
                    setAlertHeading('Failure');
                    setAlertBody('Form has failed submition')
                    break;
                case 'UpdateForm':
                    setAlertHeading('Failure');
                    setAlertBody('Form update has Failed!')
                    break;
                case 'DeleteForm':
                    setAlertHeading('Failure');
                    setAlertBody('Form deletion Failed!')
                    break;
                case 'UpdateOrder':
                    setAlertHeading('TPOU failed');
                    setAlertBody('Order update has Failed')
                    break;
                case 'CancelOrder':
                    setAlertHeading('TPOC failed');
                    setAlertBody('Order cancelation has Failed')
                    break;
                default:
                    setAlertHeading('Failure!');
                    setAlertBody('Event has Failed.');
                    break;
            }
        }
    }, [variant]);

    return (
        <Alert show={show} variant={variant}>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>
                {alertBody}
            </p>
            <hr />
            <div className="d-flex justify-content-end">
                <Button onClick={() => setShow(false)} variant={buttonVariant}>
                    Close
                </Button>
            </div>
        </Alert>
    );
}