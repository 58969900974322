import { redirect } from "react-router-dom";


export function getTokenDuration() {
    const stordeExpirationDate = localStorage.getItem("expiration");
    const expirationDate = new Date(stordeExpirationDate);
    const now = new Date();
    const duration = expirationDate.getTime() - now.getTime();
    return duration;
}

export function getAuthToken() {
    const access_token = localStorage.getItem("access_token");

    if (!access_token) {
        return null;
    }

    const tokenDuration = getTokenDuration();
    if (tokenDuration < 0) {
        return "EXPIRED"
    }

    return access_token;
}

export function tokenLoader() {
    return getAuthToken();
}

export function checkAuthToken() {
    const access_token = localStorage.getItem("access_token");

    if (!access_token) {
        return redirect('/login');
    }
    return null;
}

