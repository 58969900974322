import { useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { Pagination } from 'react-bootstrap';

import { queryClient } from '../util/http'
import LoadingIndicator from '../components/LoadingIndicator';
import EmailList from "../components/EmailsList";
import ErrorBlock from '../components/ErrorBlock';
import CurrentlySelectedEmail from '../components/CurrentlySelectedEmail';
import CompleteEmail from '../components/CompleteEmail';
import { scrollToTop } from '../util/utils';
import { CreateNullEmail, CreateNullPredictions, fetchEmails, ToggleEmailCompleted } from '../util/http';
import AlertDismissible from '../components/AlertDismissible';
import classes from './Emails.module.css'
import { handleRefreshButton } from '../util/utils';


export default function Emails() {
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [show, setShow] = useState(false);
    const [alertVariant, setAlertVariant] = useState('success')

    const pageContext = 'Emails'
    let content = null;


    const { mutate, isPending: MutationPending } = useMutation({
        mutationFn: ToggleEmailCompleted,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['emails'] });
            setShow(true);
            setAlertVariant('success');
            scrollToTop();
            // navigate('/emails');
        },
        onError: () => {
            setShow(true);
            setAlertVariant('danger');
            scrollToTop();
        }
    });

    const { mutate: createNullPredictionsMutation } = useMutation({
        mutationFn: CreateNullPredictions,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['emails'] });
            setShow(true);
            setAlertVariant('success');
            scrollToTop();
            // navigate('/emails');
        },
        onError: () => {
            setShow(true);
            setAlertVariant('danger');
            scrollToTop();
        }
    });

    const { mutate: generateNullEmail, isPending: nullEmailMutationPending } = useMutation({
        mutationFn: CreateNullEmail,
        onSuccess: (data) => {
            const email_id = data
            createNullPredictionsMutation(email_id)
        },
        onError: () => {
            setShow(true);
            setAlertVariant('danger');
            scrollToTop();
        }
    });

    function handleSetCompleteEmail() {
        const answear = window.confirm("Auftrag abschließen?")
        if (answear) { mutate(selectedEmail.id) }
    }

    function createNullEmail() {
        const answear = window.confirm("Neuen Auftrag erstellen?")
        if (answear) { generateNullEmail() }
    }

    const { data, isPending, isError, error } = useQuery({
        queryKey: ['emails', { currentPage: currentPage, completed: 'False' }],
        queryFn: ({ signal, queryKey }) => fetchEmails({ signal, ...queryKey[1], ...queryKey[2] }),
        staleTime: 15000, // 15 seconds
        // gcTime: 1000
    });

    if (isPending) {
        content = <LoadingIndicator />;
    }

    if (isError) {
        content = (
            <ErrorBlock
                title="An error occurred"
                message={error.info?.message || 'Failed to fetch emails.'}
            />
        );
    }

    if (data) {
        content = (
            <div className={classes.app_container}>
                <div className={classes.column}>
                    <h2>Aufträge</h2>
                    <button type="button" className="btn btn-primary" disabled={isPending} onClick={handleRefreshButton} >
                        {isPending ? 'Refreshing...' : 'Aktualisieren'}
                    </button>
                    <button type="button" className="btn btn-outline-success" disabled={nullEmailMutationPending} onClick={createNullEmail} >
                        {nullEmailMutationPending ? 'Creating...' : 'Neuen Auftrag erstellen'}
                    </button>

                    <EmailList emails={data} selectedEmail={selectedEmail} setSelectedEmail={setSelectedEmail} />
                    <Pagination>
                        <Pagination.First onClick={() => setCurrentPage(1)} />
                        <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} />
                        <Pagination.Item active>{currentPage}</Pagination.Item>
                        <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} disabled={data.next === null} />
                    </Pagination>
                </div>
                {selectedEmail && <>
                    <div className={classes.column}>
                        <AlertDismissible show={show} setShow={setShow} variant={alertVariant} pageContext={pageContext} />

                        <CurrentlySelectedEmail selectedEmail={selectedEmail} setCompleteButton={handleSetCompleteEmail}>
                            <button type="button" className="btn btn-outline-success" disabled={MutationPending} onClick={handleSetCompleteEmail} >
                                {MutationPending ? 'Loading...' : 'E-Mail abschließen'}
                            </button>
                        </CurrentlySelectedEmail>
                    </div>
                    <div className={classes.column}> <CompleteEmail selectedEmail={selectedEmail} /> </div>
                </>}
            </div>
        );
    }

    return (
        <>
            {content}
        </>
    );
}