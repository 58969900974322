import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Pagination } from 'react-bootstrap';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { fetchEmails } from '../util/http'
import CompletedEmail from '../components/CompletedEmail';
import EmailList from "../components/EmailsList";
import LoadingIndicator from '../components/LoadingIndicator';
import ErrorBlock from '../components/ErrorBlock';
import classes from './Emails.module.css'

function HistoryPage() {
    const [radioValue, setRadioValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedEmail, setSelectedEmail] = useState(null);
    const pageContext = 'History'
    let content = null;

    const radios = [
        { name: 'All', value: '' },
        { name: 'Completed', value: 'True' },
        { name: 'Non Completed', value: 'False' },
    ];

    const { data, isPending, isError, error } = useQuery({
        queryKey: ['emails-history', { currentPage: currentPage, completed: radioValue }],
        queryFn: ({ signal, queryKey }) => fetchEmails({ signal, ...queryKey[1], ...queryKey[2] }),
        staleTime: 120000, // 2 minutes
        // gcTime: 1000
    });

    if (isPending) {
        content = <LoadingIndicator />;
    }

    if (isError) {
        content = (
            <ErrorBlock
                title="An error occurred"
                message={error.info?.message || 'Failed to fetch emails.'}
            />
        );
    }


    if (data) {

        // TODO pagination
        // const number_of_pages = data.count
        content = (
            <div className={classes.app_container}>
                <div className={classes.column}>
                    <h2>Historie</h2>
                    <div>
                        <ButtonGroup>
                            {radios.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`radio-${idx}`}
                                    type="radio"
                                    variant={'outline-primary'}
                                    name="radio"
                                    value={radio.value}
                                    checked={radioValue === radio.value}
                                    onChange={(e) => setRadioValue(e.currentTarget.value)}
                                >
                                    {radio.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </div>
                    <EmailList emails={data} selectedEmail={selectedEmail} setSelectedEmail={setSelectedEmail} pageContext={pageContext} />
                    <Pagination>
                        <Pagination.First onClick={() => setCurrentPage(1)} />
                        <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} />
                        <Pagination.Item active>{currentPage}</Pagination.Item>
                        <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} disabled={data.next === null} />
                    </Pagination>
                </div>
                {selectedEmail && <>
                    <div className={classes.column}> <CompletedEmail selectedEmail={selectedEmail} /> </div>
                </>}
            </div>
        );
    }

    return (
        <>
            {content}
        </>
    );
}

export default HistoryPage;