import './InputWithSearch.css';

function InputWithSearch({ setAuf_code, title, data }) {
    // Function to return the whole object
    function findObject(itemKey) {
        const itemCode = itemKey.split(' | ')[0]
        // Find the index of the object with the specified item key
        const index = data.findIndex(obj => obj.code === itemCode);
        if (index !== -1) {
            setAuf_code(data[index]);
        }
    };

    return (
        <>
            <div className="main">
                <label>{title}</label>
                <input list="data" onChange={(e) => findObject(e.target.value)} placeholder="Type to filter" />
                <datalist id="data">
                    {data.map((op) => <option key={op.id}>{op.code} | {op.versandbanhof} | {op.empfaenger}</option>)}
                </datalist>
            </div>
        </>
    );
}
export default InputWithSearch;