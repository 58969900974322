import { useEffect } from "react";
import { Outlet, useLoaderData, useSubmit } from "react-router-dom";

import { getTokenDuration } from "../util/auth";
import ColoredNavBar from "../components/Navbar"


function RootLayout({ username, logout }) {
    const token = useLoaderData();
    const submit = useSubmit();
    // TODO refresh token

    useEffect(() => {
        if (!token) {
            return;
        }

        if (token === 'EXPIRED') {
            submit(null, { action: '/logout', method: 'post' });
            return;
        }

        const tokenDuration = getTokenDuration();

        setTimeout(() => {
            submit(null, { action: '/logout', method: 'post' });
        }, tokenDuration);
    }, [token, submit]);


    return <>
        <ColoredNavBar username={username} logout={logout} />
        <Outlet />
        <footer className='text-center text-lg-start bg-light text-muted mt-4'>
            <div className='text-center p-4'>

                <a target='_blank' rel='noreferrer' className='text-reset fw-bold text-decoration-none' href='https://p-a-systems.com/'>
                    © Copyright <b>P-A-Systems</b>. All Rights Reserved
                </a>
            </div>
        </footer>
    </>
}

export default RootLayout;